if (document.querySelectorAll('[data-component="carousel"]').length > 0) {

    document.addEventListener('DOMContentLoaded', function () {

        $('.slick--home').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            dots: true,
            nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
			prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
        });

        $('.slick--channel').slick({
            slidesToShow: 6,
            slidesToScroll: 2,
            autoplay: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 3,
                    }
                }, {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 3,
                    }
                }
            ]
        })

        $('.slick--program-header').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            lazyLoad: 'progressive',
            nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
			prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-white h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
            asNavFor: '.slick--program-thumb'
        })

        $('.slick--program-thumb').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: true,
            focusOnSelect: true,
            lazyLoad: 'progressive',
            asNavFor: '.slick--program-header',
            responsive: [
                {
                    breakpoint: 1023,
                    settings: {
                        slidesToShow: 3,
                    }
                }, {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 2,
                    }
                }
            ]
        })
    })
}